import { getRangeTitle } from '@/helpers/process-metrics';
import { LAST_UPDATE_DATE_FORMAT } from '@/constants';
import { switchDateFormat } from '@/helpers/format-date-service';
import { timeAgo } from '@/helpers/time-difference';
import removeProtocol from '@/helpers/remove-protocol';
import i18n from '@/i18n';

const processKeywords = ({
    getIsHideURLKeywords,
    getListOfKeywordsDomains,
}, originalKeywords) => {
    originalKeywords = _.cloneDeep(originalKeywords);

    if (!originalKeywords || !originalKeywords.length) {
        return [];
    }

    let urlStatus = {};

    originalKeywords.forEach(keyword => {
        let fullUrl = keyword.url;
        let url = keyword.url;
        const originalUrl = keyword.url;

        if (keyword.rankingurl === 'NOT FOUND') {
            keyword.grankTooltip = i18n.t('refresh-state-tooltip');
            let tempUrl = url.split('/')[0];
            if (urlStatus.hasOwnProperty(tempUrl) && urlStatus[tempUrl] === true) {
                // if the url was previously checked and the status is true
                keyword.rankingurl = 'https://' + url;
            } else {
                // if the url was not previously checked we'll have to
                // iterate over all of the data and check if any of the
                // record with same url have a rankingurl with https as
                // protocol.
                // if found https as protocol we'll add it into the urlStatus
                // array.
                // if not found we'll still add it's status to the urlStatus
                // array.
                let httpsFound = false;
                originalKeywords.forEach(kw => {
                    if (kw.url.includes(tempUrl)) {
                        if (kw.rankingurl.includes('https://')) {
                            httpsFound = true;
                            urlStatus[tempUrl] = true;
                            keyword.rankingurl = 'https://' + url;
                            return false;
                        }
                    }
                });
                if (!httpsFound) {
                    urlStatus[tempUrl] = false;
                }
            }
        }

        fullUrl = keyword.rankingurl === 'NOT FOUND' && (keyword.type === 'se' || keyword.type === 'sem')
            // We don't include the http:// here because it's 'not found' and we don't know which one it if
            ? 'http://' + removeProtocol(keyword.url)
            : keyword.rankingurl;

        if (keyword.type == 'map') {
            url = url.split('|');
            url = url[url.length - 1];
            fullUrl = 'http://' + url;
        } else if (keyword.type == 'yt') {
            if (!/youtube\.com\/watch/.test(keyword.url)) {
                url = 'youtube.com/watch?v=' + keyword.url;
            }

            fullUrl = 'https://' + url;
        } else {
            url = keyword.rankingurl !== 'NOT FOUND' && keyword.rankingurl !== ''
                ? keyword.rankingurl
                : 'http://' + keyword.url;
        }

        if (keyword.rankingurl == '') {
            fullUrl = url;
        }

        if (url == null && (keyword.type === 'se' || keyword.type === 'sem')) {
            keyword.rankingurl = keyword.url;
            url = keyword.url;
        } else {
            url = url.replace('http://', '').replace('https://', '');
        }

        // if project includes only keywords for 1 domain we should hide http(s), www, domain
        // and show only "/" or "/url"
        if (getListOfKeywordsDomains.length === 1) {
            url = url
                .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
                .replace(getListOfKeywordsDomains[0], '') || '/';
        }

        try {
            url = decodeURIComponent(url);
            fullUrl = decodeURIComponent(fullUrl);
        } catch (err) {
            console.log(err.message);
        }

        keyword.url = getIsHideURLKeywords ? url.replace(/[a-z0-9]/gi, '&bull;') : url;
        keyword.fullUrl = fullUrl;
        keyword.fullKw = keyword.kw;
        keyword.kw = getIsHideURLKeywords ? keyword.kw.replace(/[^ ]/g, '&bull;') : keyword.kw;

        keyword.flag = {
            image: '',
            tooltip: '',
        };

        keyword.flag.image = keyword.region.split('.');
        keyword.flag.image = keyword.flag.image[keyword.flag.image.length - 1];
        let language = '';
        let region = keyword.region;

        if (keyword.type === 'yt') {
            region = region.replace('google', 'youtube');
        } else {
            language = ' - language: ' + keyword.language;
        }

        keyword.flag.tooltip = `tracking: ${region}${language}`;
        keyword.originalUrl = originalUrl;
    });

    return originalKeywords;
};

const transformZeroRank = rank => rank === 0 ? 100 : rank;
const transformNumberToString = volume =>
    volume ? volume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;

const processAlerts = data => data.map(a => {
    return {
        change: transformZeroRank(a.attributes.prevrank) - transformZeroRank(a.attributes.result),
        categoryRaw: a.attributes.category,
        id: a.id,
        keyword_flag_alt: a.attributes.region.indexOf('.') > -1 ? a.attributes.region.substring(a.attributes.region.lastIndexOf('.') + 1) : 'com',
        keywordFlagTooltip: `tracking: ${a.attributes.region} - language: ${a.attributes.language}`,
        keyword: a.attributes.kw,
        keyword_id: a.attributes.keyword_id,
        prevrank: a.attributes.prevrank,
        new_rank: a.attributes.result,
        time: a.attributes.time,
        created_at: timeAgo(a.attributes.time),
        url: a.attributes.url,
        read: a.attributes.read,
        ms: transformNumberToString(a.attributes.ms),
        cpc: transformNumberToString(a.attributes.cpc),
        competition: transformNumberToString(a.attributes.competition),
    };
});

const processAlertsPivot = data => data.map(a => ({
    categoryRaw: a.attributes.category,
    id: a.id,
    keyword: a.attributes.kw,
    negativeAlerts: a.attributes.negative_items,
    positiveAlerts: a.attributes.positive_items,
    time: a.attributes.time,
    created_at: timeAgo(a.attributes.time),
    totalAlerts: a.attributes.total_items,
    url: a.attributes.url,
}));

const parseInvoiceOptions = res => {
    let options = {};

    options = {
        memo: res.data.data.attributes.memo,
        footer: res.data.data.attributes.footer,
    };

    for (let prop in res.data.data.attributes.custom_fields) {
        options[prop] = res.data.data.attributes.custom_fields[prop];
    }

    return options;
};

const metricsEmptyData = () => {
    const ranges = [];
    ranges.length = 5;
    ranges.fill({ title: '', range: '', count: 0, daily: {}, weekly: {}, monthly: {}, semester: {}, yearly: {}, life: {} }, 0, 6);

    return { total: 0, ranges, empty: true };
};

const getFetchFilters = getters => {
    const params = {
        date: getters.getKeywordFilters.keywordsDate,
        grank_max: getters.getGrankRange.max,
        grank_min: getters.getGrankRange.min,
        group: 'category',
        isfav: getters.getShowFilterForRequest,
        keyword: getters.getSearchKeyword,
        search_type: getters.getSearchType,
        page: getters.getCurrentPage,
        per_page: getters.getDisplayLength,
        sort_direction: getters.getDisplaySetting('sort_by_order'),
        sort_field: getters.getDisplaySetting('sort_by'),
        tagId: getters.getActiveTag.id || getters.getKeywordFilters.activeTag.id || null,
        type: getters.getKeywordFilters.enginesValue.split(','),
        up: getters.getChangedKeywordsOnly ? 1 : 0,
    };

    if (params.type.length === 1 && params.type[0] === '') {
        delete params.type;
    }

    return params;
};


const fastProject = name => ({
    categoryName: name,
    companyLink: '',
    companyName: '',
    description: '',
    logo: '',
    password: '',
    selectedParentCategory: null,
    currencyCode: null,
});

const parseProject = (project, parentProjectId = null) => {
    const splittedName = project.attributes.name.split('[sub]');

    return {
        ...project.attributes,
        id: project.id,
        isSubproject: splittedName.length > 1,
        name: splittedName.length > 1 ? splittedName[1] : splittedName[0],
        parent: splittedName.length > 1 ? splittedName[0] : '',
        type: project.type,
        unixTimestamp: new Date(project.attributes.updated_at).getTime(),
        parent_project_id: parentProjectId,
    };
};

const sortSuggestions = (suggestions, sort) => {
    const { field, direction } = sort;

    if (field === 'kw') {
        return suggestions.sort((a, b) =>
            direction === 'asc'
                ? a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1
                : a[field].toLowerCase() > b[field].toLowerCase() ? -1 : 1);
    }

    return suggestions.sort((a, b) => direction === 'asc'
        ? a[field] - b[field]
        : b[field] - a[field]);
};

const prepareMetricsData = (data, metricsRange) => ({
    ...data.attributes,
    id: data.id,
    name: data.id,
    charts: data.attributes.chart && data.attributes.chart.length && data.attributes.chart.map(chart => ({
        data: chart[1],
        title: chart[0],
    })),
    ranges: data.attributes.ranges.map((el, i) => ({
        ...el,
        range: metricsRange(i),
        title: getRangeTitle(metricsRange(i)),
    })),
});

const defaultLastUpdate = () => {
    return {
        relative: '',
        date: switchDateFormat(new Date(), LAST_UPDATE_DATE_FORMAT),
        timestamp: null,
    };
};

const defaultReportsSort = (field, direction = 'asc') => ({
    direction,
    field,
});

export {
    defaultLastUpdate,
    defaultReportsSort,
    fastProject,
    getFetchFilters,
    metricsEmptyData,
    parseInvoiceOptions,
    parseProject,
    sortSuggestions,
    prepareMetricsData,
    processAlerts,
    processAlertsPivot,
    processKeywords,
};
