<template>
    <rotating-update-icon v-if="showLoader" />
    <div v-else class="url-component-wrapper">
        <span
            v-if="params.data.grank === 0 && params.data.url_tracking_method==='exact'"
            class="kw kw-not-found url-not-found"
            :data-important-tooltip="urlTooltip($t('not-found'))"
        />
        <span
            v-else-if="
                params.data.grank && lockedURL
            "
            class="kw kw-lock"
            :data-important-tooltip="$t('https-detected')"
        />
        <span
            v-if="params.data.url_tracking_method==='exact'"
            class="kw kw-crosshair target_exact_icon"
            @mouseover="
                tooltip({
                    text: $t('tracking-method', { type, ignoreSub }),
                    event: $event,
                })
            "
        />
        <span
            v-if="params.data.url_tracking_method==='broad'"
            class="icon-wrapper"
            @mouseover="
                tooltip({
                    text: $t('tracking-method', { type, ignoreSub }),
                    event: $event,
                })
            "
        >
            <span class="kw kw-maximize-2" />
            <span class="kw kw-maximize-2" />
        </span>
        <div
            v-if="params.data.grank === 0 && params.data.url_tracking_method==='exact'"
            class="table-cell__url-link-blok table-cell__content_url"
        >
            <a
                class="table-cell__url-link"
                :href="clearUrl(params.data.fullUrl)"
                target="_blank"
                v-html="clearUrl(params.data.url)"
            />
        </div>
        <div
            v-else-if="
                params.data.grank === 0 && !params.data.url_tracking_method==='exact' && !isMultiDomain
            "
        >
            <span class="kw kw-not-found not-found__icon" />
            <span class="not-found" v-html="$t('not-found')" />
        </div>
        <div v-else class="table-cell__content table-cell__content_url">
            <a
                class="table-cell__url-link"
                :data-important-tooltip="urlTooltip(params.data.fullUrl)"
                :href="params.data.fullUrl"
                target="_blank"
                v-html="clearUrl(params.data.url)"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import RotatingUpdateIcon from '@/components/ui-elements/RotatingUpdateIcon';
import { capitalize } from '@/helpers';

export default {
    name: 'UrlRenderer',
    components: { RotatingUpdateIcon },
    mixins: [ToolTipRenderMixin],
    computed: {
        ...mapGetters(['getListOfKeywordsDomains','getIsRefreshRunning','getCurrentRefreshingKeywords']),
        isMultiDomain() {
            return this.getListOfKeywordsDomains.length > 1;
        },
        lockedURL() {
            return (
                this.params.data.rankingurl !== null &&
                this.params.data.rankingurl.indexOf('https') === 0
            );
        },
        showLoader() {
            return this.getIsRefreshRunning && this.getCurrentRefreshingKeywords.includes(this.params.data.id);
        },
        ignoreSub() {
            return this.params.data.ignore_sub_domains ? 'Yes' : 'No';
        },
        type() {
            return capitalize(this.params.data.url_tracking_method);
        }
    },
    methods: {
        urlTooltip(url) {
            return `<a href="${url}" target="_blank">${url}</a>`;
        },
        clearUrl(url) {
            return url.indexOf('?') < 0
                ? url
                : url.substring(0, url.indexOf('?'));
        },
    }
};
</script>

<style lang="scss" scoped>
.url-component-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    gap: 5px;
    padding: 0 15px 0 20px;
    position: relative;

    .url-not-found {
        color: #63697b;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        left: 3px;
    }

    .kw-lock {
        color: kw-color(kw-grey, 1);
        font-size: 12px;
        font-weight: 700;
        left: 3px;
        position: absolute;
    }

    .table-cell__url-not-found {
        color: kw-color(kw-grey, 1);
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        left: 3px;
    }

    .table-cell__content_url {
        display: grid;
    }

    .table-cell__url-link {
        color: kw-color(kw-blue, 1);
        display: block;
        @import '~sass/cutting-name';
    }

    .not-found__icon {
        color: kw-color(kw-grey, 1);
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        left: 3px;
        top: 9px;
    }

    .not-found {
        color: #ccc;
        text-transform: uppercase;
    }

    .target_exact_icon {
        color: kw-color(kw-grey, 1);
        font-size: 12px;
        font-weight: 700;
        margin-right: 5px;
    }
    .icon-wrapper{ 
        position: relative;
        display: inline-block;
        width: 12px;
        height: 12px;
        font-size: 11px;
        .kw-maximize-2 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .kw-maximize-2:nth-child(2){
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }
}
</style>
